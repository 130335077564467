import styled from 'styled-components';

export const StyledEditorContainer = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const StyledEditor = styled.div`
  width: 100%;
  margin: 2rem;
  ${(p) => p.theme.elevation1};
  padding: 2rem;
  border-radius: 5px;
`;
export const DropzoneContainer = styled.div`
  margin-top: 2rem;
  max-width: 450px;
`;
export const StyledPreview = styled.div`
  margin-top: 2rem;
  width: 500px;
  height: 500px;
`;

export const StyledTitle = styled.div`
  margin-top: 1rem;
  font-size: 2rem;
`;
export const StyledDescription = styled.div`
  font-size: 1.4rem;
`;
export const Placeholder = styled.div`
  width: 280px;
  height: 100%;
  ${(p) => p.theme.elevation3};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`;
export const ImageContainer = styled.div`
  width: 450px;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    ${(p) => p.theme.elevation3};
    max-width: 100%;
    max-height: 100%;
    text-align: center;
    border-radius: 5px;
  }
`;
