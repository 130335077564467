import { combineReducers } from '@reduxjs/toolkit';
import postsReducer from './posts';
import postReducer from './post';
import shortsReducer from './features/shorts/shorts';
import shortReducer from './features/shorts/short';

export default combineReducers({
  posts: postsReducer,
  post: postReducer,
  shorts: shortsReducer,
  short: shortReducer,
});
