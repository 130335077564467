import firebase from 'firebase/app';
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// const firebaseConfig = {
//   apiKey: 'AIzaSyCfWkYY4Wj9BLiFJdEHyl4hE9B_sT6yAwQ',
//   authDomain: 'deepak-test-bang.firebaseapp.com',
//   projectId: 'deepak-test-bang',
//   storageBucket: 'deepak-test-bang.appspot.com',
//   messagingSenderId: '774803942983',
//   appId: '1:774803942983:web:3a774b719146406ec3cb62',
// };
const firebaseConfig = {
  apiKey: 'AIzaSyBhRHN50OBJGiWLlCIhQIVay6D9_zbvAm0',
  authDomain: 'drdeepakacharya-prod.firebaseapp.com',
  projectId: 'drdeepakacharya-prod',
  storageBucket: 'drdeepakacharya-prod.appspot.com',
  messagingSenderId: '683348625786',
  appId: '1:683348625786:web:b96fd5c5855068baf86ec3',
  measurementId: 'G-23D2CVW913',
};

const app = firebase.initializeApp(firebaseConfig);

export const storage = app.storage();
export const db = app.firestore();
export const timestamp = firebase.firestore.FieldValue.serverTimestamp;
export const ts = firebase.firestore.Timestamp;
export const auth = app.auth();
export default app;
