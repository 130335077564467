export const lightTheme = {
  body: '#fff',
  text: '#212121',
  textLight: '#393939',
  background: '#ffffff',
  highlight: '#EBEBEB',
  primary: '#FF7F11',
  primaryDark: '#DD6600',
  primaryLight: '#ff9a44',
  red: '#F25C54',
  green: '#90EE90',
  shadow:
    'rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 20%) 0px 3px 6px, rgb(15 15 15 / 40%) 0px 9px 24px',
  shadowSoft: 'rgba(100, 100, 111, 0.18) 0px 7px 29px 0px;',
  link: '#DD6600',
  focus: '#f1f1f1',
  gray1: '#fafafa',
  gray2: '#f5f5f5',
  gray3: '#eeeeee',
  gray4: '#e0e0e0',
  gray5: '#bdbdbd',
  gray6: '#9E9E9E',
  gray7: '#757575',
  gray8: '#616161',
  gray9: '#424242',
  gray10: '#212121',
  white: '#ffffff',
  black: '#000000',
  isDark: false,
  fontFamily: `"Mukta", sans-serif`,
  elevation0: '#ffffff',
  elevation1: `background: #ffffff; box-shadow:0 0px 0.3px rgba(0, 0, 0, 0.02),  0 0px 0.7px rgba(0, 0, 0, 0.028),  0 0px 1.3px rgba(0, 0, 0, 0.035),  0 0px 2.2px rgba(0, 0, 0, 0.042),  0 0px 4.2px rgba(0, 0, 0, 0.05),0 0px 10px rgba(0, 0, 0, 0.07)`,
  elevation2: `background: #ffffff; box-shadow: 0 0px 0.6px rgba(0, 0, 0, 0.02),  0 0px 1.3px rgba(0, 0, 0, 0.028),  0 0px 2.5px rgba(0, 0, 0, 0.035),  0 0px 4.5px rgba(0, 0, 0, 0.042),  0 0px 8.4px rgba(0, 0, 0, 0.05),  0 0px 20px rgba(0, 0, 0, 0.07)`,
  elevation3: `background: #ffffff; box-shadow:  0 0px 0.8px rgba(0, 0, 0, 0.02),  0 0px 2px rgba(0, 0, 0, 0.028), 0 0px 3.8px rgba(0, 0, 0, 0.035),  0 0px 6.7px rgba(0, 0, 0, 0.042),  0 0px 12.5px rgba(0, 0, 0, 0.05), 0 0px 30px rgba(0, 0, 0, 0.07)`,
};

export const darkTheme = {
  body: '#161616',
  text: '#FFFFFF',
  textLight: '#EBEBEB',
  highlight: '#393939',
  background: '#252525',
  primary: '#FF7F11',
  primaryDark: '#DD6600',
  primaryLight: '#ff9a44',
  red: '#F25C54',
  green: '#90EE90',
  shadow:
    'rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 20%) 0px 3px 6px, rgb(15 15 15 / 40%) 0px 9px 24px',
  shadowSoft: ' 0px 7px 29px 0px rgb(0 0 0 / 30%);',
  link: '#FF7F11',
  focus: '#131313',
  gray1: '#212121',
  gray2: '#424242',
  gray3: '#616161',
  gray4: '#757575',
  gray5: '#9e9e9e',
  gray6: '#bdbdbd',
  gray7: '#e0e0e0',
  gray8: '#eeeeee',
  gray9: '#f5f5f5',
  gray10: '#fafafa',
  white: '#ffffff',
  black: '#000000',
  isDark: true,
  fontFamily: `"Mukta", sans-serif`,
  elevation0: `background: #121212`,
  elevation1: `background: #1D1D1D`,
  elevation2: `background: #212121`,
  elevation3: `background: #242424`,
  elevation4: `background: #272727`,
  elevation5: `background: #2C2C2C`,
  elevation6: `background: #2D2D2D`,
  elevation7: `background: #323232`,
  elevation8: `background: #353535`,
  elevation9: `background: #373737`,
};
