import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectShortData,
  updateShortTitle,
} from '../../../../../store/features/shorts/short';
import { StyledTitle } from './styles';

const Title = () => {
  const dispatch = useDispatch();
  const { title } = useSelector(selectShortData);
  const onChange = (e) => {
    dispatch(updateShortTitle(e.target.value));
  };

  return (
    <StyledTitle
      type="text"
      name="title"
      placeholder="Title"
      value={title}
      onChange={onChange}
    />
  );
};

export default Title;
