import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectPostData,
  postPublishModal,
  createDraft,
  reset,
} from '../../../store/post';
import PublishModal from './PublishModal/PublishModal';
import { errorAdded } from '../../../store/error';
import { HeaderButton, StyledEditorContainer, StyledHeader } from './styles';
import PostEditor from './PostEditor/PostEditor';

const CreatePost = () => {
  const { title } = useSelector(selectPostData);

  const dispatch = useDispatch();

  const handleDraft = () => {
    if (!title) {
      return dispatch(
        errorAdded({
          message: 'Title is a Required Field!',
        })
      );
    }

    dispatch(createDraft());
  };

  return (
    <StyledEditorContainer>
      <StyledHeader>
        <HeaderButton type="button" onClick={handleDraft}>
          Draft
        </HeaderButton>
        <HeaderButton
          type="button"
          secondary
          onClick={() => dispatch(postPublishModal(true))}
        >
          Publish
        </HeaderButton>
      </StyledHeader>
      <PostEditor />
      <PublishModal />
    </StyledEditorContainer>
  );
};

export default CreatePost;
