import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  deletePost,
  loadPostDataById,
  reset,
  updatePost,
} from '../../../store/post';
import PostEditor from './PostEditor/PostEditor';
import { HeaderButton, StyledEditorContainer, StyledHeader } from './styles';

const EditPost = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(loadPostDataById(id));
    return () => {
      dispatch(reset());
    };
  }, [dispatch, id]);

  const onDelete = () => {
    dispatch(deletePost(id));
    history.push('/');
  };

  return (
    <StyledEditorContainer>
      <StyledHeader>
        <HeaderButton type="button" onClick={onDelete}>
          Delete
        </HeaderButton>
        <HeaderButton type="button" onClick={() => dispatch(updatePost(id))}>
          Update
        </HeaderButton>
      </StyledHeader>
      <PostEditor />
    </StyledEditorContainer>
  );
};

export default EditPost;
