import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import LoadingBar from 'react-top-loading-bar';
import { selectPostLoading } from '../../store/post';

const ProgressBar = () => {
  const ref = useRef(null);
  const loading = useSelector(selectPostLoading);
  useEffect(() => {
    if (ref && loading) {
      ref.current.continuousStart();
    }
    if (!loading) {
      ref.current.complete();
    }
  }, [loading]);

  return (
    <>
      <LoadingBar color="#f11946" ref={ref} />
    </>
  );
};

export default ProgressBar;
