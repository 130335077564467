import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectShortData,
  updateShortImage,
} from '../../../../store/features/shorts/short';
import Dropzone from '../../../shared/Dropzone/Dropzone';
import Description from './Description/Description';
import {
  StyledEditor,
  StyledPreview,
  StyledEditorContainer,
  DropzoneContainer,
  ImageContainer,
  Placeholder,
  StyledTitle,
  StyledDescription,
} from './styles';
import Title from './Title/Title';

const ShortEditor = () => {
  const { title, description, image } = useSelector(selectShortData);
  const dispatch = useDispatch();
  const onChange = (imageURL) => {
    dispatch(updateShortImage(imageURL));
  };
  return (
    <StyledEditorContainer>
      <StyledEditor>
        <Title />
        <DropzoneContainer>
          <Dropzone setFileURL={onChange} imageUrl={image} />
        </DropzoneContainer>
        <Description />
      </StyledEditor>
      <StyledPreview>
        <ImageContainer>
          {image ? (
            <img src={image} alt="Short Featured" />
          ) : (
            <Placeholder>
              <h1>1080 x 1920</h1>
            </Placeholder>
          )}
        </ImageContainer>
        <StyledTitle>{title || 'Title will appear here!'}</StyledTitle>
        <StyledDescription>
          {description || 'Description will appear here!'}
        </StyledDescription>
      </StyledPreview>
    </StyledEditorContainer>
  );
};

export default ShortEditor;
