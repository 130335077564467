import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  data: {
    title: '',
    image: '',
    description: '',
  },
};
const shortSlice = createSlice({
  name: 'short',
  initialState,
  reducers: {
    updateShortTitle: (state, action) => {
      state.data.title = action.payload;
    },
    updateShortImage: (state, action) => {
      state.data.image = action.payload;
    },
    updateShortDescription: (state, action) => {
      state.data.description = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  updateShortTitle,
  updateShortImage,
  updateShortDescription,
  reset,
} = shortSlice.actions;

export const selectShortData = (state) => state.entities.short.data;

export default shortSlice.reducer;
