import { createSlice } from '@reduxjs/toolkit';
import { db, timestamp, ts } from '../firebase/firebase';
import { errorAdded } from './error';
import { postAdded, postRemoved } from './posts';

const initialState = {
  isLoading: false,
  publishModal: false,
  isDraft: false,
  status: null,
  lastSaved: 0,
  data: {
    title: '',
    description: '',
    content: '',
    featuredImage: '',
    categories: ['common'],
    tags: [],
    publishDate: Date.now(),
    private: false,
    createdAt: Date.now(),
  },
};

const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    // DATACHANGE
    postDataChanged: (post) => {
      post.isLoading = true;
    },
    postPublished: (post) => {
      post.data = initialState.data;
      post.isLoading = false;
      post.status = 'success';
    },

    postPublishModal: (post, action) => {
      post.publishModal = action.payload;
    },
    postDrafted: (post) => {
      post.isLoading = true;
      post.isDraft = true;
    },
    postTitleChanged: (post, action) => {
      post.data.title = action.payload;
    },
    postDescriptionChanged: (post, action) => {
      post.data.description = action.payload;
    },
    postImageChanged: (post, action) => {
      post.data.featuredImage = action.payload;
    },
    postCategoriesChanged: (post, action) => {
      post.data.categories = action.payload;
    },
    postTagsChanged: (post, action) => {
      post.data.tags = action.payload;
    },
    postPublishDateChanged: (post, action) => {
      post.data.publishDate = action.payload;
    },
    postLoading: (post) => {
      post.isLoading = true;
    },
    postContentChanged: (post, action) => {
      post.data.content = action.payload;
    },

    // SAVING
    postUpdated: (post) => {
      post.isLoading = false;
    },
    postDataLoaded: (post, action) => {
      post.data = action.payload;
      post.isLoading = false;
    },

    resetPost: () => initialState,
  },
});

export const {
  postDataChanged,
  postTitleChanged,
  postDescriptionChanged,
  postImageChanged,
  postCategoriesChanged,
  postTagsChanged,
  postPublishModal,
  postContentChanged,
  postPublishDateChanged,

  postLocalSaved,
  postDrafted,
  postPublished,
  postUpdated,
  postLoading,
  postDataLoaded,
  resetPost,
} = postSlice.actions;

// ACTION CREATORS

// CREATE

export const createDraft = () => async (dispatch, getState) => {
  const postData = getState().entities.post.data;

  try {
    await db.collection('drafts').add({
      ...postData,
      createdAt: timestamp(),
      publishDate: ts.fromMillis(postData.publishDate),
    });
    dispatch(postDrafted());
  } catch (e) {
    dispatch(errorAdded({ message: e.message }));
  }
};

export const createPost = () => async (dispatch, getState) => {
  dispatch(postLoading());
  const postData = getState().entities.post.data;
  try {
    const postRef = await db.collection('posts').add({
      ...postData,
      createdAt: timestamp(),
      publishDate: ts.fromMillis(postData.publishDate),
    });
    dispatch(postAdded({ id: postRef.id, ...postData }));
    dispatch(postPublished());
  } catch (e) {
    dispatch(errorAdded({ message: e.message }));
  }
};

// UPDATE
export const loadPostDataById = (id) => async (dispatch) => {
  dispatch(postLoading());
  try {
    const postData = (await db.collection('posts').doc(id).get()).data();
    postData.publishDate = postData.publishDate.toMillis();
    postData.createdAt = postData.createdAt.toMillis();
    dispatch(postDataLoaded(postData));
  } catch (error) {
    console.log(error);
  }
};

export const updatePost = (id) => async (dispatch, getState) => {
  dispatch(postLoading());
  const postData = getState().entities.post.data;
  try {
    await db
      .collection('posts')
      .doc(id)
      .update({
        ...postData,
        createdAt: ts.fromMillis(postData.createdAt),
        publishDate: ts.fromMillis(postData.publishDate),
      });
    dispatch(postUpdated());
  } catch (e) {
    dispatch(errorAdded({ message: e.message }));
  }
};
// UTILS

export const reset = () => (dispatch) => {
  dispatch(resetPost());
};

// DELETE

export const deletePost = (id) => async (dispatch) => {
  dispatch(postLoading());
  try {
    await db.collection('posts').doc(id).delete();
    dispatch(resetPost());
    dispatch(postRemoved(id));
  } catch (e) {
    dispatch(errorAdded({ message: e.message }));
  }
};

// LOCAL

export const selectPost = (state) => state.entities.post;
export const selectPostData = (state) => state.entities.post.data;
export const selectPublishModal = (state) => state.entities.post.publishModal;
export const selectPostLoading = (state) => state.entities.post.isLoading;
export const selectPostStatus = (state) => state.entities.post.status;

export default postSlice.reducer;
