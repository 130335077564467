import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset, selectShortData } from '../../../store/features/shorts/short';
import ShortEditor from './ShortEditor/ShortEditor';
import { StyledEditorContainer, StyledHeader, HeaderButton } from './styles';

const CreateShort = () => {
  const { title, image } = useSelector(selectShortData);
  const dispatch = useDispatch();
  return (
    <StyledEditorContainer>
      <StyledHeader>
        {/* <HeaderButton
          type="button"
          onClick={() => {
            dispatch(reset());
          }}
        >
          Publish
        </HeaderButton> */}
      </StyledHeader>
      <ShortEditor />
    </StyledEditorContainer>
  );
};

export default CreateShort;
