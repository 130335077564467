import React from 'react';
import styled from 'styled-components';

const StyledName = styled.h2`
  color: '#FF7F11';
`;

const Logo = () => <StyledName>Dr. Deepak Acharya</StyledName>;

export default Logo;
