import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  selectShortData,
  updateShortDescription,
} from '../../../../../store/features/shorts/short';

const StyledInput = styled.input`
  width: 100%;
  display: block;
  outline: none;
  padding: 1rem;
  border: none;
  border-radius: 8px;
  font-size: 1.4rem;
  font-family: Mukta, sans-serif;
  color: ${(p) => p.theme.text};
  ${(p) => p.theme.elevation3};
  margin-top: 2rem;
`;

const Description = () => {
  const dispatch = useDispatch();
  const { description } = useSelector(selectShortData);
  return (
    <StyledInput
      type="text"
      onChange={(e) => dispatch(updateShortDescription(e.target.value))}
      value={description}
      placeholder="Description"
    />
  );
};
export default Description;
