import { rgba } from 'polished';
import styled from 'styled-components';

export const StyledTitle = styled.input`
  width: 100%;
  display: block;
  outline: none;
  padding: 0;
  border: none;
  border-radius: 10px;
  font-size: 4rem;
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.text};
  background: transparent;
  &::placeholder {
    color: ${(props) => rgba(props.theme.text, 0.5)};
  }
`;
