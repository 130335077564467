import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  list: [],
};
const shortsSlice = createSlice({
  name: 'shorts',
  initialState,
  reducer: {
    loadShorts: (state, action) => {
      state.list = state.list.concat(action.payload);
      state.isLoading = false;
    },
  },
});

export const { loadShorts } = shortsSlice.actions;

export const selectShorts = (state) => state.entities.shorts.list;

export default shortsSlice.reducer;
