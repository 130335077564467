import { toast } from 'react-toastify';
import { errorAdded } from '../error';

const toastMiddleware = () => (next) => (action) => {
  if (action.type === errorAdded.type) {
    toast.error(action.payload);
    next(action);
  } else next(action);
};

export default toastMiddleware;
