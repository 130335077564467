import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  postDescriptionChanged,
  selectPostData,
} from '../../../../../store/post';

const StyledInput = styled.input`
  width: 100%;
  display: block;
  outline: none;
  padding: 1rem;
  border: none;
  border-radius: 8px;
  font-size: 1.4rem;
  font-family: Mukta, sans-serif;
  color: ${(p) => p.theme.text};
  ${(p) => p.theme.elevation3}
`;

const Description = () => {
  const dispatch = useDispatch();
  const { description } = useSelector(selectPostData);
  return (
    <div>
      <h3>Description</h3>
      <StyledInput
        type="text"
        onChange={(e) => dispatch(postDescriptionChanged(e.target.value))}
        value={description}
        placeholder="Description"
      />
    </div>
  );
};
export default Description;
